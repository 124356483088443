const futures = [
  'hokkaido',
  'hiroshima',
  'chiba',
  'kagoshima',
  'tokyostar',
  'awa',
  'kyoto',
  'hokuriku',
  'kansaimirai',
  'musashino',
  'shinsei',
  'shizuoka',
];

function isFuture(bank) {
  if (bank && bank.name) {
    return futures.includes(bank.name);
  }
  return false;
}

export { isFuture };
