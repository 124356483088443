<script>
  import CodeVerification from '../../containers/CodeVerification.svelte';
  import { otpShape, otpInitial } from './validation.js';

  export let codeType;

  const verificationTime = 2 * 60 - 5; // 1:55
</script>

<CodeVerification
  initialTimer={verificationTime}
  {codeType}
  validations={{
    otp: {
      inital: otpInitial,
      shape: otpShape,
    },
  }}
  on:verificationSubmit
/>
