import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  branchNumber: '',
  login: '',
  password: '',
};

const loginShape = yup.object().shape({
  branchNumber: yup
    .string()
    .matches(/^\d+$/g, errors.onlyNumbers)
    .length(3, errors.incorrectLength)
    .required(errors.fieldRequired),
  login: yup
    .string()
    .matches(/^\d+$/g, errors.onlyNumbers)
    .length(7, errors.incorrectLength)
    .required(errors.fieldRequired),
  password: yup.string().required(errors.fieldRequired),
});

const pinShape = yup.object().shape({
  pin: yup
    .string()
    .matches(/^\d+$/g, errors.onlyNumbers)
    .length(5, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const setShape = yup.object().shape({
  pin: yup
    .string()
    .matches(/^\d+$/g, errors.onlyNumbers)
    .length(5, errors.incorrectLength)
    .required(errors.fieldRequired),
  firstNumber: yup.string().matches(/^\d+$/g, errors.onlyNumbers).length(1, errors.incorrectLength),
  secondNumber: yup
    .string()
    .matches(/^\d+$/g, errors.onlyNumbers)
    .length(1, errors.incorrectLength),
});

const answerShape = yup.object().shape({
  answer: yup.string().max(20, errors.incorrectLength).required(errors.fieldRequired),
});

const otpShape = yup.object().shape({
  otpCode: yup.string().matches(/^\d+$/g, errors.onlyNumbers).length(6, errors.incorrectLength),
});

export { loginInitialValues, loginShape, pinShape, setShape, answerShape, otpShape };
