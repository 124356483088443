<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import {
    verificationText,
    general as generalStrings,
    aujibun as aujibunStrings,
  } from '../../resources/strings';
  import { customVerificationShape, customVerificationInitialValues } from './validation';
  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import api from '../../api';
  import timer from '../../stores/timer.store';
  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  export let codeType;
  export let customQuestion;

  $: strings = verificationText[codeType];
  $: parsedCustomQuestion = JSON.parse(customQuestion);

  $: chooseOtpMethod = !Array.isArray(parsedCustomQuestion);

  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(115));

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: customVerificationInitialValues,
    validationSchema: customVerificationShape,
    onSubmit: (submittedForm) =>
      api.postCode($transaction.id, codeType, JSON.stringify(submittedForm)).then(() => {
        dispatch('verificationSubmit');
        timer.resetTimer();
      }),
  });
</script>

<form class="form-container" on:submit={handleSubmit}>
  <div class="container">
    {#if codeType === 'custom'}
      {#if chooseOtpMethod}
        <p>{aujibunStrings.chooseOtp}</p>
        {#each parsedCustomQuestion.options as option, i (option.value)}
          <label>
            <input
              type="radio"
              name="otpMethod"
              id="otpMethod{i}"
              bind:group={$form.otpMethod}
              checked={$form.otpMethod === option.value}
              value={`${option.value}`}
              on:change={handleChange}
            />
            <span>{option.text}</span>
          </label>
        {/each}
      {:else}
        <Input
          label={`キャッシュカード裏面に記載の表の ${
            JSON.parse(customQuestion)[0]
          } にある数字を含み ${JSON.parse(customQuestion)[1]} の数字をご入力ください。`}
          focus
          id={codeType}
          type="password"
          name="custom"
          placeholder={strings.placeholder}
          bind:value={$form.custom}
          error={$errors.custom}
          touched={$touched.custom}
          {handleChange}
        />
      {/if}
    {/if}
    {#if $localError.verification}
      <p class="error">{$localError.verification}</p>
    {/if}
    <div class="button-container">
      <Button type="submit">{generalStrings.next}</Button>
    </div>
  </div>
</form>

<style>
  .form-container {
    width: 100%;
    padding: 1rem;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .error {
    color: red;
  }
</style>
