import { errors, errorOverwrite } from './error-map';

function getError(errorKey) {
  if (typeof errorKey === 'string' && isKnownError(errorKey)) {
    return errors[errorKey];
  }
  return errors.Unknown;
}

function isKnownError(errorKey) {
  return Object.keys(errors).includes(errorKey);
}

function getOverwrittenErrorName(errorKey) {
  return errorOverwrite[errorKey] ?? errorKey;
}

export { isKnownError, getError, getOverwrittenErrorName };
