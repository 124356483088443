import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  accountNumberInput: true,
  branchNumber: '',
  accountNumber: '',
  contactNumber: '',
  password: '',
};

const loginShape = yup.object().shape({
  accountNumberInput: yup.boolean().required(),

  branchNumber: yup.string().when('accountNumberInput', {
    is: true,
    then: yup
      .string()
      .length(3, errors.incorrectLength)
      .matches(/^\d+$/g, errors.onlyNumbers)
      .required(errors.fieldRequired),
    otherwise: yup.string().notRequired(),
  }),

  accountNumber: yup.string().when('accountNumberInput', {
    is: true,
    then: yup
      .string()
      .length(7, errors.incorrectLength)
      .matches(/^\d+$/g, errors.onlyNumbers)
      .required(errors.fieldRequired),
    otherwise: yup.string().notRequired(),
  }),

  contactNumber: yup.string().when('accountNumberInput', {
    is: false,
    then: yup.string().required(errors.fieldRequired),
    otherwise: yup.string().notRequired(),
  }),

  password: yup.string().required(errors.fieldRequired),
});

export { loginInitialValues, loginShape };
