<script>
  import CodeVerification from '../../containers/CodeVerification.svelte';
  import CustomQuestionVerification from './CustomQuestionVerification.svelte';
  import { aeon as aeonStrings } from '../../resources/strings';
  import TransactionPasswordVerification from './TransactionPasswordVerification.svelte';
  import {
    otpVerificationShape,
    emailCodeVerificationShape,
    verificationCodeInitial,
  } from './validation';
  import SetVerification from './SetVerification.svelte';
  import OtpInstructions from './OtpInstructions.svelte';

  export let codeType;
  export let customQuestion;

  const otpVerificationTime = 1.5 * 60 - 5;
  const emailCodeVerificationTime = 5 * 60 - 5;
</script>

<div class="container">
  {#if codeType === 'custom' && customQuestion === aeonStrings.transactionPassword}
    <TransactionPasswordVerification on:verificationSubmit />
  {:else if codeType === 'custom'}
    <CustomQuestionVerification on:verificationSubmit />
  {:else if codeType === 'otp'}
    <CodeVerification
      {codeType}
      initialTimer={otpVerificationTime}
      validations={{
        otp: {
          initial: verificationCodeInitial,
          shape: otpVerificationShape,
        },
      }}
      on:verificationSubmit
    />
    <div class="instructions">
      <OtpInstructions />
    </div>
  {:else if codeType === 'set'}
    <SetVerification on:verificationSubmit />
  {:else if codeType === 'emailCode' && customQuestion === aeonStrings.receptionCode}
    <CodeVerification
      {codeType}
      initialTimer={emailCodeVerificationTime}
      validations={{
        emailCode: {
          initial: verificationCodeInitial,
          shape: emailCodeVerificationShape,
        },
      }}
      {customQuestion}
      on:verificationSubmit
    />
    <div class="instructions">{aeonStrings.emailConfirmationInstruction}</div>
  {:else}
    <CodeVerification
      {codeType}
      initialTimer={emailCodeVerificationTime}
      validations={{
        emailCode: {
          initial: verificationCodeInitial,
          shape: emailCodeVerificationShape,
        },
      }}
      {customQuestion}
      on:verificationSubmit
    />
  {/if}
</div>

<style>
  .container {
    width: 100%;
  }

  .instructions {
    padding: 1rem 2rem 0;
  }
</style>
