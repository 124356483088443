<script>
  import { onMount } from 'svelte';

  import Status from '../../containers/Status/Status.svelte';
  import Loading from '../../components/Loading.svelte';
  import api from '../../api';
  import { transaction } from '../../stores/transaction.store';
  import { progress } from '../../stores/transaction-progress.store';
  import { setError, errors as errorsConst } from '../../stores/error.store';

  let loading = true;

  onMount(async () => {
    await api.startFakeTransaction($transaction.id).then(async (res) => {
      if (res?.ok === false) {
        $progress = 0;
        const data = await res.json();
        if (data.current) {
          setError(data.current, data);
        } else {
          setError(errorsConst.Unknown);
        }
      }
    });
    loading = false;
  });
</script>

{#if loading}
  <Loading />
{:else}
  <Status type="Success" message="Success" />
{/if}
