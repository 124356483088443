<script>
  import { fly } from 'svelte/transition';

  import { transaction } from '../../stores/transaction.store';
  import { updateError } from '../../stores/error.store';
  import { state, updateState } from '../../stores/state.store';
  import { paypay as paypayStrings } from '../../resources/strings';

  import { isLoading, isLogin, isVerification } from '../../utils/stateUtils';
  import CodeVerification from '../../containers/CodeVerification.svelte';
  import Loading from '../../components/Loading.svelte';
  import Login from './Login.svelte';
  import { otpInitial, otpShape } from './validation';
  import AppInstruction from './AppInstruction.svelte';
  import PhoneVerification from './PhoneVerification.svelte';

  const verificationTime = 2 * 60 - 5; // 1:55

  async function handleStateChange() {
    try {
      await updateState($transaction.id);
    } catch {
      $state.current = 'connectionLost';
    }
    updateError($state.current);
  }
</script>

<div class="card-content">
  {#if isLoading($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <Loading />
    </div>
  {:else if isLogin($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <Login on:back on:loginSubmit={handleStateChange} />
    </div>
  {:else if isVerification($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      {#if $state.current.replace('Retry', '') === 'appAuth'}
        <div class="app-instruction-container">
          <AppInstruction />
        </div>
      {/if}
      {#if $state.current.replace('Retry', '') === 'telephone'}
        <PhoneVerification
          codeType={$state.current.replace('Retry', '')}
          customQuestion={$state.customQuestion}
          on:phoneVerificationSubmit={handleStateChange}
        />
      {:else}
        <CodeVerification
          initialTimer={verificationTime}
          codeType={$state.current.replace('Retry', '')}
          customQuestion={($state.current.replace('Retry', '') === 'otp' ||
            $state.current.replace('Retry', '') === 'appAuth') &&
            paypayStrings.otpForTransaction}
          validations={{
            otp: {
              inital: otpInitial,
              shape: otpShape,
            },
            appAuth: {
              inital: otpInitial,
              shape: otpShape,
            },
          }}
          on:verificationSubmit={handleStateChange}
        />
      {/if}
    </div>
  {/if}
</div>

<style>
  .card-content {
    display: grid;
    place-items: center;
    padding: 3rem;
  }

  .transition {
    display: grid;
    place-items: center;
    width: 100%;
  }

  .app-instruction-container {
    width: 100%;
    display: grid;
    place-items: center;
  }
  @media (max-width: 550px) {
    .card-content {
      padding: 2rem 0;
    }
  }
</style>
