<script>
  import CodeVerification from '../../containers/CodeVerification.svelte';
  import MutliAccount from './MutliAccount.svelte';
  import {
    otpShape,
    otpInitial,
    emailCodeInitial,
    emailCodeShape,
    telephoneInitial,
    telephoneShape,
  } from './validation.js';

  export let codeType;
  
  const verificationTime = 2 * 60 - 5; // 1:55
</script>

{#if codeType === 'custom'}
  <MutliAccount initialTimer={verificationTime} on:verificationSubmit />
{:else}
  <CodeVerification
    initialTimer={verificationTime}
    {codeType}
    validations={{
      otp: {
        inital: otpInitial,
        shape: otpShape,
      },
      emailCode: {
        initial: emailCodeInitial,
        shape: emailCodeShape,
      },
      telephone: {
        initial: telephoneInitial,
        shape: telephoneShape,
      },
    }}
    on:verificationSubmit
  />
{/if}
