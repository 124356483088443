<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import { fly } from 'svelte/transition';
  import { transaction } from '../../stores/transaction.store';
  import { selectedBank } from '../../stores/selected-bank.store';
  import { localError, setError, errors as errorsConst } from '../../stores/error.store';
  import { startProgress, progress } from '../../stores/transaction-progress.store';
  import { sony as sonyStrings } from '../../resources/strings';
  import { loginShape, loginInitialValues } from './validation';

  import Input from '../../components/Input.svelte';
  import LoginButtonsGroup from '../../containers/LoginButtonsGroup.svelte';
  import api from '../../api';

  const dispatch = createEventDispatcher();

  let submitted = false;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: loginInitialValues,
    validationSchema: loginShape,
    onSubmit: (submittedForm) => {
      if (!submitted) {
        submitted = true;
        return api
          .startCrawler(
            $transaction.id,
            {
              branchNumber: submittedForm.branchNumber,
              accountNumber: submittedForm.login,
              password: submittedForm.password,
            },
            $selectedBank.name,
          )
          .then(async (res) => {
            if (res?.ok === false) {
              $progress = 0;
              const data = await res.json();
              if (data.current) {
                setError(data.current, data);
              } else {
                setError(errorsConst.Unknown);
              }
            } else {
              startProgress();
              dispatch('loginSubmit');
            }
          });
      }
    },
  });
</script>

<form on:submit={handleSubmit} class="container">
  <div>
    <div class="login-form" in:fly={{ x: -200, duration: 300 }}>
      <div class="login-container">
        <Input
          name="branchNumber"
          type="text"
          label={sonyStrings.branchNumber}
          placeholder={sonyStrings.branchNumberPlaceholder}
          bind:value={$form.branchNumber}
          error={$errors.branchNumber}
          touched={$touched.branchNumber}
          {handleChange}
        />
      </div>
      <div class="login-container">
        <Input
          name="login"
          type="text"
          label={sonyStrings.login}
          placeholder={sonyStrings.loginPlaceholder}
          bind:value={$form.login}
          error={$errors.login}
          touched={$touched.login}
          {handleChange}
        />
      </div>
      <div class="password-container">
        <Input
          name="password"
          type="password"
          label={sonyStrings.password}
          placeholder={sonyStrings.passwordPlaceholder}
          bind:value={$form.password}
          error={$errors.password}
          touched={$touched.password}
          {handleChange}
        />
      </div>
    </div>
    {#if $localError.login}
      <p class="error">{$localError.login}</p>
    {/if}
    <LoginButtonsGroup on:back />
  </div>
</form>

<style>
  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    padding: 0 2rem;
  }

  .login-container {
    width: 100%;
    margin-bottom: 2rem;
  }

  .password-container {
    width: 100%;
  }

  .login-form {
    display: flex;
    flex-direction: column;
  }
  .error {
    color: red;
  }
</style>
