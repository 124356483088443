import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  customerNumber: '',
};

const loginShape = yup.object().shape({
  customerNumber: yup
    .string()
    .min(8, errors.incorrectLength)
    .max(10, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const customInitialValues = {
  custom: '',
};

const customShape = yup.object().shape({
  custom: yup.string().required(errors.fieldRequired),
});

const otpInitialValues = {
  otp: '',
};

const otpShape = yup.object().shape({
  otp: yup.string().length(8, errors.incorrectLength).required(errors.fieldRequired),
});

const hiddenCharInitialValues = {
  letter1: '',
  letter2: '',
  letter3: '',
  letter4: '',
};

const hiddenCharShape = yup.object().shape({
  letter1: yup.string().length(1, errors.incorrectLength).required(errors.fieldRequired),

  letter2: yup.string().length(1, errors.incorrectLength).required(errors.fieldRequired),

  letter3: yup.string().length(1, errors.incorrectLength).required(errors.fieldRequired),

  letter4: yup.string().length(1, errors.incorrectLength).required(errors.fieldRequired),
});

const emailCodeInitialValues = {
  emailCode: '',
};

const emailCodeShape = yup.object().shape({
  emailCode: yup.string().length(5, errors.incorrectLength).required(errors.fieldRequired),
});

export {
  loginInitialValues,
  loginShape,
  customInitialValues,
  customShape,
  otpInitialValues,
  otpShape,
  hiddenCharInitialValues,
  hiddenCharShape,
  emailCodeInitialValues,
  emailCodeShape,
};
