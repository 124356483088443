import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  userName: '',
  password: '',
};

const loginShape = yup.object().shape({
  userName: yup
    .string()
    .min(1, errors.incorrectLength)
    .max(32, errors.incorrectLength)
    .required(errors.fieldRequired),
  password: yup
    .string()
    .min(4, errors.incorrectLength)
    .max(32, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const hiddenCharVerificationInitialValues = {
  answers: [],
};

const hiddenCharVerificationShape = yup.object().shape({
  answers: yup
    .array()
    .of(
      yup
        .string()
        .length(2, errors.incorrectLength)
        .matches(/^\d+$/g, errors.onlyNumbers)
        .required(errors.fieldRequired),
    ),
});

const emailCodeInitial = {
  emailCode: '',
};

const emailCodeShape = yup.object().shape({
  emailCode: yup.string().required(errors.fieldRequired),
});

const customQuestionInitial = {
  customQuestion: '',
};

const customQuestionShape = yup.object().shape({
  customQuestion: yup.string().max(20, errors.incorrectLength).required(errors.fieldRequired),
});

export {
  loginInitialValues,
  loginShape,
  hiddenCharVerificationInitialValues,
  hiddenCharVerificationShape,
  emailCodeInitial,
  emailCodeShape,
  customQuestionInitial,
  customQuestionShape,
};
