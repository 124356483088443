<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';

  import api from '../api';

  import { verificationText, general as generalStrings } from '../resources/strings';
  import { transaction } from '../stores/transaction.store';
  import { localError } from '../stores/error.store';

  import Button from '../components/Button.svelte';
  import Input from '../components/Input.svelte';
  import timer from '../stores/timer.store';

  export let codeType;
  export let customQuestion;
  export let validations;
  export let inputType = 'text';
  export let initialTimer = 5 * 60 - 5; // 4:55

  $: strings = verificationText[codeType];

  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(initialTimer));

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: validations[codeType].initial,
    validationSchema: validations[codeType].shape,
    onSubmit: (submittedForm) =>
      api.postCode($transaction.id, codeType, submittedForm.verificationCode).then(() => {
        dispatch('verificationSubmit');
        timer.resetTimer();
      }),
  });
</script>

<form on:submit={handleSubmit} class="container">
  <Input
    label={customQuestion ? customQuestion : strings.title}
    name="verificationCode"
    type={inputType}
    placeholder={strings.placeholder}
    bind:value={$form.verificationCode}
    error={$errors.verificationCode}
    touched={$touched.verificationCode}
    {handleChange}
  />
  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}
  <div class="button-container">
    <Button type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 0 2rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .error {
    color: red;
  }
</style>
