<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';

  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';

  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  import api from '../../api';

  import { general as generalStrings } from '../../resources/strings';
  import { otpInitialValues, otpShape } from './validation';
  import { verificationText } from '../../resources/strings';

  export let codeType;

  const dispatch = createEventDispatcher();

  $: strings = verificationText[codeType];

  onMount(() => timer.setNewTimer(115));

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: otpInitialValues,
    validationSchema: otpShape,
    onSubmit: (submittedForm) =>
      api.postCode($transaction.id, codeType, submittedForm.otp).then(() => {
        timer.resetTimer();
        dispatch('verificationSubmit');
      }),
  });
</script>

<form class="form-container" on:submit={handleSubmit}>
  <div class="container">
    <Input
      label={strings.title}
      placeholder={strings.placeholder}
      id="otp"
      name="otp"
      type="text"
      bind:value={$form.otp}
      error={$errors.otp}
      touched={$touched.otp}
      {handleChange}
    />
    {#if $localError.verification}
      <p class="error">{$localError.verification}</p>
    {/if}
    <div class="button-container">
      <Button type="submit">{generalStrings.next}</Button>
    </div>
  </div>
</form>

<style>
  .form-container {
    width: 100%;
    padding: 0 2rem;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .error {
    color: red;
  }
</style>
