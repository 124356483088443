<script>
  import { createEventDispatcher } from 'svelte';

  import Button from '../components/Button.svelte';

  import { general as generalStrings } from '../resources/strings';

  const dispatch = createEventDispatcher();

  function back() {
    dispatch('back');
  }
</script>

<div class="button-container">
  <Button type="button" on:click={back} color="#b71c1c">{generalStrings.back}</Button>
  <Button type="submit">{generalStrings.next}</Button>
</div>

<style>
  .button-container {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 600px) {
    .button-container {
      gap: 1rem;
      flex-direction: column-reverse;
      justify-content: center;
    }
  }
</style>
