import { writable } from 'svelte/store';
import LogRocket from 'logrocket';

import { setError } from './error.store';

import { createTransaction, getTransactionData, postDomain } from '../api';

import { getFrameData } from '../utils/locationUtils';
import { isInIframe } from '../utils/iframeUtils';
import { KEYS, saveToLocalStorage } from '../utils/localstorageUtils';

const transaction = writable({});

async function startTransaction(id) {
  transaction.update((prevTransaction) => ({
    ...prevTransaction,
    name: '',
    surname: '',
    id: '',
    amount: '',
    backgroundUrl: '',
  })); // initial values

  let transactionDetails = null;

  if (id) {
    transactionDetails = await getTransactionData(id);
    console.log('API transactionDetails', transactionDetails);
  } else {
    const frameData = getFrameData();

    try {
      transactionDetails = await createTransaction(frameData);
      console.log('URL transactionDetails', transactionDetails);
    } catch (e) {
      setError(e);
    }
  }

  if (!transactionDetails) {
    setError('InternalError');
    return;
  }

  try {
    await postDomain(merchant_id, {
      referrer: document.referrer,
      ancestorOrigins: document.location.ancestorOrigins,
      nonce: transactionDetails.nonce,
      isIframe: isInIframe(),
    });
  } catch {
    // skip - this is temporary
  }

  saveToLocalStorage(KEYS.SYSTEM_ID, transactionDetails.systemId);
  transaction.update((prevTransaction) => ({
    ...prevTransaction,
    ...transactionDetails,
  }));
  LogRocket.identify(transactionDetails.id);
}

export { transaction, startTransaction };
export default transaction;
