<script>
  import CodeVerification from '../../containers/CodeVerification.svelte';
  import { sony as sonyStrings } from '../../resources/strings';
  import { otpShape } from './validation';
  import SetVerification from './SetVerification.svelte';
  import QuestionVerification from './QuestionVerification.svelte';
  import PinVerification from './PinVerification.svelte';
  import AppVerification from './AppVerification.svelte';

  export let codeType;
  export let customQuestion;

  const otpVerificationTime = 2 * 60 - 5;
</script>

<div class="container">
  {#if codeType === 'set'}
    <SetVerification on:verificationSubmit />
  {:else if codeType === 'appAuth'}
    <AppVerification on:verificationSubmit />
  {:else if codeType === 'otp'}
    <div>
      <div class="instructions">
        {sonyStrings.otpInstruction}
      </div>
      <CodeVerification
        {codeType}
        validations={{ otp: { initial: { otp: '' }, shape: otpShape } }}
        initialTimer={otpVerificationTime}
        on:verificationSubmit
      />
    </div>
  {:else if codeType === 'custom' && customQuestion === sonyStrings.transactionPin}
    <PinVerification on:verificationSubmit />
  {:else}
    <QuestionVerification on:verificationSubmit />
  {/if}
</div>

<style>
  .container {
    width: 100%;
  }

  .instructions {
    margin-bottom: 1rem;
  }
</style>
