<script>
  import Card from '../components/Card.svelte';
  import BankCard from './BankCard.svelte';
  import Loading from '../components/Loading.svelte';
  import SearchBar from '../components/SearchBar.svelte';
  import SelectButton from '../components/SelectButton.svelte';

  import { banks, setSearchQuery } from '../stores/banks.store';
  import { transaction } from '../stores/transaction.store';
  import { selectedBank, setSelectedBank, updateRedirectBank } from '../stores/selected-bank.store';

  import { general as generalStrings } from '../resources/strings';
  import { isOutsideOfOperatingHours, isUnderMaintenancePeriod } from '../utils/dateUtils';
  import { shouldRedirectToManualTransfer } from '../utils/redirectUtils';

  let preselectedBank = { name: '', nameJapanese: '', nameEnglish: '', loading: false };

  function handleRadioSelection(bank) {
    preselectedBank = bank;
  }

  function handleBankSelection() {
    if (preselectedBank.name !== '') {
      if (shouldRedirectToManualTransfer($transaction.semiRedirectBanks, preselectedBank.name)) {
        // Clear search query
        setSearchQuery('');

        setSelectedBank({
          name: 'manual',
          nameJapanese: '通常振込',
          nameEnglish: 'ATMや銀行アプリなどをご利用',
          loading: false,
        });
        updateRedirectBank(preselectedBank.name);
        return;
      }

      setSelectedBank(preselectedBank);
    }
  }
</script>

{#if !$transaction.id || $selectedBank.loading}
  <Card>
    <Loading />
  </Card>
{:else}
  <SearchBar />
  <Card>
    {#each $banks as bank (bank)}
      <BankCard
        checked={preselectedBank.name === bank.name}
        on:click={() => {
          if (isUnderMaintenancePeriod(bank) || isOutsideOfOperatingHours(bank.operatingHours))
            return;
          handleRadioSelection(bank);
        }}
        {bank}
      />
    {/each}
  </Card>
  <div class="container-fixed">
    <SelectButton on:click={handleBankSelection} disabled={preselectedBank.name === ''}>
      {generalStrings.acceptSelection}
    </SelectButton>
  </div>
{/if}

<style>
  .container-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 1rem;
    display: grid;
    place-items: center;
  }
</style>
