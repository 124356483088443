<script>
  export let numbers = [];
  export let label = '';
</script>

<div class="container">
  <p class="label">{label}</p>
  <div class="numbers">
    {#each numbers as number}
      <div class="number">{number}</div>
    {/each}
  </div>
</div>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .label {
    text-align: center;
    font-size: 0.9rem;
  }

  .numbers {
    display: flex;
    grid-template-columns: repeat(6, 1fr);
    margin: 0 auto;
    gap: 0.3rem
  }

  .number {
    color: var(--text-main-light);
    background-color: var(--gray-text);
    border: none;
    border-radius: 4px;
    padding: 0.3rem
  }
</style>
