<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';

  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import { state } from '../../stores/state.store';
  import timer from '../../stores/timer.store';

  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  import api from '../../api';

  import { general as generalStrings, suruga } from '../../resources/strings';
  import { customQuestionInitial, customQuestionShape } from './validation';

  export let codeType;

  const customQuestion = $state.customQuestion;
  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(115));

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: customQuestionInitial,
    validationSchema: customQuestionShape,
    onSubmit: (submittedForm) =>
      api.postCode($transaction.id, codeType, submittedForm.customQuestion).then(() => {
        timer.resetTimer();
        dispatch('verificationSubmit');
      }),
  });
</script>

<form class="form-container" on:submit={handleSubmit}>
  <div class="container">
    {#if codeType === 'custom'}
      <Input
        name="customQuestion"
        label={customQuestion}
        id="customQuestion"
        type="text"
        placeholder={suruga.customQuestionPlaceholder}
        maxlength="20"
        bind:value={$form.customQuestion}
        error={$errors.customQuestion}
        touched={$touched.customQuestion}
        {handleChange}
      />
    {/if}
    {#if $localError.verification}
      <p class="error">{$localError.verification}</p>
    {/if}
    <div class="button-container">
      <Button type="submit">{generalStrings.next}</Button>
    </div>
  </div>
</form>

<style>
  .form-container {
    width: 100%;
    padding: 0 2rem;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .error {
    color: red;
  }
</style>
