<script>
  import { general as generalStrings } from '../resources/strings';
  import convertToDisplayPrice from '../utils/displayUtils';

  export let price;
  $: displayPrice = convertToDisplayPrice(price);
</script>

<div class="container">
  <span class="amount-jpy">{generalStrings.amountJPY}</span>
  <div class="price-container">
    <h3 class="price">{displayPrice}</h3>
    <span class="yen">{generalStrings.yen}</span>
  </div>
</div>

<style>
  .container {
    display: grid;
    grid-template-rows: 1fr auto;
  }

  .price-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  span,
  h3 {
    text-align: left;
    text-align: -webkit-left;
  }

  .amount-jpy {
    font-size: 0.9rem;
    font-weight: 300;
  }

  .price {
    font-size: 2.7rem;
    line-height: 2.8rem;
    font-weight: 500;
  }

  .yen {
    font-size: 1.2rem;
    font-weight: 400;
  }
</style>
