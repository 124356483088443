import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  isLoginIdInput: false,
  loginId: '',
  branchNumber: '',
  accountNumber: '',
  password: '',
};

const loginShape = yup.object().shape({
  isLoginIdInput: yup.boolean().required(),

  branchNumber: yup
    .string()
    .length(3, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),

  accountNumber: yup
    .string()
    .length(7, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),

  loginId: yup.string().when('isLoginIdInput', {
    is: true,
    then: yup
      .string()
      .min(6, errors.incorrectLength)
      .max(32, errors.incorrectLength)
      .required(errors.fieldRequired),
    otherwise: yup.string().notRequired(),
  }),

  password: yup
    .string()
    .min(4, errors.incorrectLength)
    .max(32, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const otpInitial = {
  verificationCode: '',
};

const otpShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

export { loginInitialValues, loginShape, otpInitial, otpShape };
