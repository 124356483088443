<script>
  import aphConfig from '@aph-config';

  const src = `${aphConfig.banksLogosUrl}/paypay-app.png`;
</script>

<img {src} alt="paypay app instructions" />

<style>
  img {
    width: clamp(160px, 100%, 400px);
    height: auto;
  }
</style>
