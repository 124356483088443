<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import { fly } from 'svelte/transition';

  import { transaction } from '../../stores/transaction.store';
  import { selectedBank } from '../../stores/selected-bank.store';
  import { localError, setError, errors as errorsConst } from '../../stores/error.store';
  import { startProgress, progress } from '../../stores/transaction-progress.store';

  import api from '../../api';

  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  import {
    loginShape,
    loginBiometricsShape,
    loginInitialValues,
    loginBiometricsInitialValues,
  } from './validation';

  import { japanpost as bankStrings, general as generalStrings } from '../../resources/strings';

  const dispatch = createEventDispatcher();

  export let loginMethod;

  const validations = {
    password: {
      initialValues: loginInitialValues,
      shape: loginShape,
    },
    biometrics: {
      initialValues: loginBiometricsInitialValues,
      shape: loginBiometricsShape,
    },
  };

  let submitted = false;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: validations[loginMethod].initialValues,
    validationSchema: validations[loginMethod].shape,
    onSubmit: (submittedForm) => {
      if (!submitted) {
        submitted = true;
        return api
          .startCrawler(
            $transaction.id,
            {
              accountNumber: `${submittedForm.accountNumberOne}-${submittedForm.accountNumberTwo}-${submittedForm.accountNumberThree}`,
              password: submittedForm.password || '',
            },
            $selectedBank.name,
          )
          .then(async (res) => {
            if (res?.ok === false) {
              $progress = 0;
              const data = await res.json();
              if (data.current) {
                setError(data.current, data);
              } else {
                setError(errorsConst.Unknown);
              }
            } else {
              startProgress();
              dispatch('loginSubmit');
            }
          });
      }
    },
  });

  function backToLogin() {
    dispatch('backToLogin');
  }
</script>

<form on:submit={handleSubmit} class="container">
  <div class="customer-number-inputs" in:fly={{ x: -200, duration: 300 }}>
    <Input
      label={`${bankStrings.customerNumber} - パート1`}
      id="accountNumberOne"
      name="accountNumberOne"
      type="text"
      placeholder={bankStrings.fourDigits}
      bind:value={$form.accountNumberOne}
      error={$errors.accountNumberOne}
      touched={$touched.accountNumberOne}
      {handleChange}
      classLabel="label"
    />
    <Input
      label={`${bankStrings.customerNumber} - パート2`}
      id="accountNumberTwo"
      name="accountNumberTwo"
      type="text"
      placeholder={bankStrings.fourDigits}
      bind:value={$form.accountNumberTwo}
      error={$errors.accountNumberTwo}
      touched={$touched.accountNumberTwo}
      {handleChange}
      classLabel="label"
    />
    <Input
      label={`${bankStrings.customerNumber} - パート3`}
      id="accountNumberThree"
      name="accountNumberThree"
      type="text"
      placeholder={bankStrings.fiveDigits}
      bind:value={$form.accountNumberThree}
      error={$errors.accountNumberThree}
      touched={$touched.accountNumberThree}
      {handleChange}
      classLabel="label"
    />
  </div>
  {#if loginMethod === 'password'}
    <div class="password-container">
      <Input
        label={bankStrings.loginPassword}
        id="password"
        type="password"
        name="password"
        placeholder=""
        bind:value={$form.password}
        error={$errors.password}
        touched={$touched.password}
        {handleChange}
      />
    </div>
  {/if}
  {#if $localError.login}
    <div class="login-error">
      <p class="error">{$localError.login}</p>
    </div>
  {/if}
  <div class="button-container">
    <Button class="button-custom " type="button" on:click={backToLogin} color="#b71c1c"
      >{generalStrings.backToLogin}</Button
    >
    <Button class="button-custom " type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    padding: 0 2rem;
  }

  .customer-number-inputs {
    display: flex;
    gap: 2rem;
    padding-bottom: 2rem;
  }
  :global(.label) {
    flex: 1;
  }
  .password-container {
    width: 100%;
  }
  .button-container {
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    gap: 1rem;
  }

  :global(.button-custom) {
    min-height: 37px;
    min-width: 160px;
    flex: 1;
  }

  .login-error {
    width: 100%;
    display: grid;
    place-items: center;
  }
  .error {
    color: red;
  }
  @media (max-width: 560px) {
    .customer-number-inputs {
      flex-direction: column;
    }
  }
</style>
