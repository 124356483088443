<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import { fly } from 'svelte/transition';
  import Input from '../../components/Input.svelte';
  import LoginButtonsGroup from '../../containers/LoginButtonsGroup.svelte';
  import RadioSign from '../../components/RadioSign.svelte';
  import { transaction } from '../../stores/transaction.store';
  import { selectedBank } from '../../stores/selected-bank.store';
  import { localError, setError, errors as errorsConst } from '../../stores/error.store';
  import { startProgress, progress } from '../../stores/transaction-progress.store';
  import api from '../../api';
  import { yokohama as yokohamaStrings } from '../../resources/strings';
  import { loginShape, loginInitialValues } from './validations';

  const dispatch = createEventDispatcher();

  let submitted = false;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: loginInitialValues,
    validationSchema: loginShape,
    onSubmit: (submittedForm) => {
      if (!submitted) {
        submitted = true;
        return api
          .startCrawler(
            $transaction.id,
            {
              branchNumber: submittedForm.branchNumber,
              accountNumber: submittedForm.accountNumber,
              contactNumber: submittedForm.contactNumber,
              password: submittedForm.password,
            },
            $selectedBank.name,
          )
          .then(async (res) => {
            if (res?.ok === false) {
              $progress = 0;
              const data = await res.json();
              if (data.current) {
                setError(data.current, data);
              } else {
                setError(errorsConst.Unknown);
              }
            } else {
              startProgress();
              dispatch('loginSubmit');
            }
          });
      }
    },
  });
</script>

<form on:submit={handleSubmit} class="container">
  <div>
    <div class="input-type-selection">
      <RadioSign
        label={yokohamaStrings.accountSelection}
        value={true}
        bind:group={$form.accountNumberInput}
        checked={$form.accountNumberInput}
        on:change={handleChange}
      />
      <RadioSign
        label={yokohamaStrings.contact}
        value={false}
        bind:group={$form.accountNumberInput}
        checked={!$form.accountNumberInput}
        on:change={handleChange}
      />
    </div>

    {#if $form.accountNumberInput}
      <div class="account-container" in:fly={{ x: -200, duration: 300 }}>
        <Input
          name="branchNumber"
          label={yokohamaStrings.branch}
          placeholder={yokohamaStrings.branchPlaceholder}
          bind:value={$form.branchNumber}
          error={$errors.branchNumber}
          touched={$touched.branchNumber}
          {handleChange}
        />
        <Input
          name="accountNumber"
          label={yokohamaStrings.account}
          placeholder={yokohamaStrings.accountPlaceholder}
          bind:value={$form.accountNumber}
          error={$errors.accountNumber}
          touched={$touched.accountNumber}
          {handleChange}
        />
      </div>
    {:else}
      <div class="contact-container" in:fly={{ x: 200, duration: 300 }}>
        <Input
          name="contactNumber"
          label={yokohamaStrings.contact}
          placeholder={yokohamaStrings.contactPlaceholder}
          bind:value={$form.contactNumber}
          error={$errors.contactNumber}
          touched={$touched.contactNumber}
          {handleChange}
        />
      </div>
    {/if}
  </div>

  <div class="password-container">
    <Input
      name="password"
      type="password"
      label={yokohamaStrings.password}
      placeholder={yokohamaStrings.passwordPlaceholder}
      bind:value={$form.password}
      error={$errors.password}
      touched={$touched.password}
      {handleChange}
    />
  </div>
  {#if $localError.login}
    <div class="login-error">
      <p class="error">{$localError.login}</p>
    </div>
  {/if}
  <LoginButtonsGroup on:back />
</form>

<style>
  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    padding: 0 2rem;
  }

  .input-type-selection {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .account-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
  }

  .contact-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .password-container {
    width: 100%;
  }

  .login-error {
    width: 100%;
    display: grid;
    place-items: center;
  }

  .error {
    text-align: center;
    color: red;
  }
</style>
