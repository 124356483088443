<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';

  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';

  import api from '../../api';

  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  import { gmoazora as gmoazoraStrings, general as generalStrings } from '../../resources/strings';

  import { birthDateInitial, birthDateShape } from './validation';

  export let codeType;
  export let initialTimer = 5 * 60 - 5; // 4:55

  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(initialTimer));

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: birthDateInitial,
    validationSchema: birthDateShape,
    onSubmit: (submittedForm) =>
      api.postCode($transaction.id, codeType, submittedForm.birthDate).then(() => {
        dispatch('birthDateverificationSubmit');
        timer.resetTimer();
      }),
  });
</script>

<form on:submit={handleSubmit} class="container">
  <Input
    label={gmoazoraStrings.birthday}
    name="birthDate"
    type="text"
    placeholder={gmoazoraStrings.birthdayInputPlaceholder}
    bind:value={$form.birthDate}
    error={$errors.birthDate}
    touched={$touched.birthDate}
    {handleChange}
  />
  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}

  <div>
    <p class="title">{gmoazoraStrings.birthdayAuthTitle}</p>
    <p class="title">{gmoazoraStrings.birthdayAuthExample}</p>
  </div>

  <div class="button-container">
    <Button type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    place-items: center;
    gap: 1rem;
    padding: 0 2rem;
  }

  .title {
    margin: 20px 0;
    text-align: center;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .error {
    color: red;
    text-align: center;
  }
</style>
