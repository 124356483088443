import * as wanakana from 'wanakana';

function filterByQuery(banksList, filter) {
  const query = filter.toLowerCase();

  return banksList.filter((bank) => {
    const engToHiragana = wanakana.toHiragana(bank.nameEnglish).split(' ').join('');
    const engToKatakana = wanakana.toKatakana(bank.nameEnglish).split(' ').join('');

    return (
      bank.nameEnglish.toLowerCase().includes(query) ||
      bank.nameJapanese.toLowerCase().includes(query) ||
      engToHiragana.includes(query) ||
      engToKatakana.includes(query)
    );
  });
}

export { filterByQuery };
export default filterByQuery;
