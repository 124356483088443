<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import Button from '../../components/Button.svelte';
  import { state } from '../../stores/state.store';
  import timer from '../../stores/timer.store';
  import { transaction } from '../../stores/transaction.store';
  import { general as generalStrings } from '../../resources/strings';
  import api from '../../api';
  import { multiAccountShape } from './validation';

  export let initialTimer;

  const dispatch = createEventDispatcher();

  let availableAccounts = [];

  onMount(() => {
    timer.setNewTimer(initialTimer);
    availableAccounts = JSON.parse($state.customQuestion);
  });

  const { form, errors, handleChange, handleSubmit } = createForm({
    validationSchema: multiAccountShape,
    onSubmit: (submittedForm) => {
      api
        .postCode($transaction.id, 'custom', submittedForm.selectedAccountIndex)
        .then(() => {
          dispatch('verificationSubmit');
          timer.resetTimer();
        })
        .catch((e) => console.log(e));
    },
  });
</script>

<form on:submit={handleSubmit} class="container">
  <h3 class="header">引落口座をお選びください。</h3>
  <div class="accounts-list">
    {#each availableAccounts as { number, balance }, i}
      <label class="account-card" for="selectedAccountIndex{i}">
        <input
          on:change={handleChange}
          type="radio"
          name="selectedAccountIndex"
          id="selectedAccountIndex{i}"
          bind:group={$form.selectedAccountIndex}
          checked={$form.selectedAccountIndex === i}
          value={i}
        />
        <div class="account-details">
          <p><strong class="bold">{number}</strong></p>
          <p>{balance}</p>
        </div>
      </label>
    {/each}
  </div>
  {#if $errors}
    <small>{$errors.selectedAccountIndex}</small>
  {/if}
  <div class="button-container">
    <Button type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  h3,
  p {
    overflow-wrap: normal;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 0 2rem;
  }

  .header {
    font-weight: bold;
    font-size: 1.1rem;
  }

  .accounts-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .account-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    overflow-wrap: normal;
  }

  .account-details {
    width: 100%;
  }

  .bold {
    font-weight: bold;
  }

  small {
    color: red;
    font-size: 0.8rem;
  }
</style>
