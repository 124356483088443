import { writable } from 'svelte/store';
import { getError, isKnownError } from '../../../../shared/error-service';
import { errors } from '../../../../shared/error-map';

export { updateError, setError, generalError, globalError, localError, errors, resetAllErrors };

const generalError = writable('');

const globalError = writable(null);

const localError = writable({
  login: '',
  verification: '',
});

function updateError(state) {
  if (isKnownError(state)) {
    setError(state);
  } else {
    resetError();
  }
}

function setError(state, data) {
  const error = getError(state);
  if (error.type === 'general') {
    generalError.update(() => error.message);
  } else if (error.type === 'global') {
    globalError.update(() => ({ ...error, ...data }));
  } else {
    localError.update((previous) => ({
      ...previous,
      [error.type]: error.message,
    }));
  }
}
function resetAllErrors() {
  resetError();
  resetGlobalError();
}

function resetError() {
  localError.update(() => ({
    login: '',
    verification: '',
  }));
}

function resetGlobalError() {
  generalError.update(() => '');
}
