<script>
  import { onDestroy } from 'svelte';
  import { fly } from 'svelte/transition';

  import { setError } from '../../stores/error.store';
  import {
    manualTransaction,
    resetManualTransaction,
    setManualTransaction,
  } from '../../stores/manual-transaction.store';
  import { selectedBankForRedirect } from '../../stores/selected-bank.store';
  import { transaction } from '../../stores/transaction.store';
  import api from '../../api';

  import { manualBank as strings } from '../../resources/strings';

  import Card from '../../components/Card.svelte';
  import ClipboardMultipleOutline from 'svelte-material-icons/ClipboardMultipleOutline.svelte';
  import Loading from '../../components/Loading.svelte';
  import Notification from './notification.svelte';

  let transferSummary = false;
  let isNotificationConfirmed = false;

  async function handleManualTransaction() {
    isNotificationConfirmed = true;
    try {
      await api
        .startManualTransaction($transaction.id, $selectedBankForRedirect)
        .then(async (res) => {
          const data = await res.json();
          if (!res.ok) {
            setError(data.current);
          } else {
            setManualTransaction(data);
          }
        });
    } catch (e) {
      setError(e);
    }
  }

  onDestroy(() => resetManualTransaction());

  function handleSaveToClipboard(text) {
    navigator.clipboard.writeText(text);
  }
</script>

<div class="card-content">
  {#if !isNotificationConfirmed}
    <Notification on:back on:notificationConfirmation={handleManualTransaction} />
  {:else if !$manualTransaction.transaction}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <Loading />
    </div>
  {:else if transferSummary}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <p class="summary-title">取引は正常に完了しました。</p>
      <p class="summary-text">着金が確認され次第、お客様の口座に反映されます。</p>
    </div>
  {:else}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <Card>
        <p class="title">{strings.title}</p>
        {#each Object.entries(strings.displayData) as [key, string]}
          <div class="row">
            <p class="row-title">{string}</p>
            <p class="row-value">
              {key === 'amount'
                ? `${$manualTransaction.transaction[key]} 円`
                : $manualTransaction.transaction[key]}
            </p>
            <button
              class="copy-button"
              on:click={() => handleSaveToClipboard($manualTransaction.transaction[key])}
            >
              <ClipboardMultipleOutline width={15} height={15} />
              <span>{strings.copy}</span>
            </button>
          </div>
        {/each}

        <div class="description-container">
          <p>{strings.descriptionTitle}</p>
          <ol>
            <li>{strings.descriptionOne}</li>
            <li>{strings.descriptionTwo}</li>
            <li>{strings.descriptionThree}</li>
          </ol>
          <p>*{strings.descriptionWarningOne}</p>
          <p>*{strings.descriptionWarningTwo}</p>
        </div>
      </Card>
    </div>
  {/if}
</div>

<style>
  .card-content {
    display: grid;
    place-items: center;
    padding: 3rem;
  }

  .transition {
    display: grid;
    place-items: center;
    width: 100%;
  }

  .summary-title {
    padding: 2rem 0;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 700;
    text-align: center;
  }

  .title,
  .summary-text {
    padding: 1rem 0;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;
    color: var(--gray-text);
    text-align: center;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
  }

  .row-title {
    font-size: 1.1rem;
    color: var(--gray-dark);
    font-weight: bold;
  }

  .row-value {
    flex-grow: 1;
    text-align: right;
  }

  .copy-button {
    border: none;
    border-radius: 4px;
    padding: 0.5rem;
    transition: all 0.3s ease 0s;
    color: var(--white);
    background: var(--blue-main);
    font-weight: bold;
    font-size: 0.7rem;
    box-shadow:
      inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.07),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.05),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.03);
    text-align: center;
  }

  .copy-button:hover,
  .copy-button:focus {
    cursor: pointer;
    background: var(--blue-main-darker);
  }

  .copy-button:active {
    box-shadow:
      inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    background: var(--blue-main-dark);
  }

  /* .button-container {
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
  } */

  .description-container {
    padding: 1rem;
    color: var(--gray-text-light);
  }

  ol {
    padding-left: 1rem;
  }

  @media (max-width: 550px) {
    .card-content {
      padding: 2rem 0;
    }
  }
</style>
