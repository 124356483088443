<script>
  import { onMount } from 'svelte';

  import { transaction } from '../../stores/transaction.store';
  import { state, updateStateWithPlaceholder } from '../../stores/state.store';
  import { localError, updateError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';

  import api from '../../api';

  import { aujibun as aujibunStrings } from '../../resources/strings';

  $: if ($state.current.includes('Retry')) {
    timer.setNewTimer(10 * 60);
    api.postCode($transaction.id, 'appAuth', 'ready');
    updateStateWithPlaceholder($transaction.id, 'appAuth')
      .then((newState) => updateError(newState.current))
      .catch((e) => updateError(e));
  }

  onMount(() => {
    timer.setNewTimer(10 * 60);
    api.postCode($transaction.id, 'appAuth', 'ready');
    updateStateWithPlaceholder($transaction.id, 'appAuth')
      .then((newState) => updateError(newState.current))
      .catch((e) => updateError(e));
  });
</script>

<div class="container">
  <h3 class="title">{aujibunStrings.appAuthTitle}</h3>
  <p class="support-text">{aujibunStrings.appAuthInfo}</p>
  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}
</div>

<style>
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    place-items: center;
    gap: 1rem;
    padding: 0 2rem;
  }

  .title {
    font-weight: bold;
    text-align: center;
  }

  .error {
    color: red;
    text-align: center;
  }
</style>
