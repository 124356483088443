<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import api from '../../api';

  import Button from '../../components/Button.svelte';

  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';

  import { paypay, general as generalStrings } from '../../resources/strings';

  export let codeType;
  export let customQuestion;
  let initialTimer = 2 * 60 - 35; // 1:25

  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(initialTimer));

  function submitPhoneVerification() {
    api.postCode($transaction.id, codeType, 'true').then(() => {
      dispatch('phoneVerificationSubmit');
      timer.resetTimer();
    });
  }
</script>

<div class="container">
  <p>{paypay.phoneVerification}</p>
  <p>{customQuestion}</p>
  <p>{paypay.phoneVerificationConfirmation}</p>
  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}
  <div class="button-container">
    <Button on:click={submitPhoneVerification}>{generalStrings.next}</Button>
  </div>
</div>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 0 2rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .error {
    color: red;
  }
</style>
