import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  accountNumberInput: true,
  branchNumber: '',
  accountNumber: '',
  contactNumber: '',
  password: '',
};

const loginShape = yup.object().shape({
  accountNumberInput: yup.boolean().required(),

  branchNumber: yup.string().when('accountNumberInput', {
    is: true,
    then: yup
      .string()
      .length(3, errors.incorrectLength)
      .matches(/^\d+$/g, errors.onlyNumbers)
      .required(errors.fieldRequired),
    otherwise: yup.string().notRequired(),
  }),

  accountNumber: yup.string().when('accountNumberInput', {
    is: true,
    then: yup
      .string()
      .length(7, errors.incorrectLength)
      .matches(/^\d+$/g, errors.onlyNumbers)
      .required(errors.fieldRequired),
    otherwise: yup.string().notRequired(),
  }),

  contactNumber: yup.string().when('accountNumberInput', {
    is: false,
    then: yup
      .string()
      .min(8, errors.incorrectLength)
      .max(10, errors.incorrectLength)
      .matches(/^\d+$/g, errors.onlyNumbers)
      .required(errors.fieldRequired),
    otherwise: yup.string().notRequired(),
  }),

  password: yup
    .string()
    .min(4, errors.incorrectLength)
    .max(16, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const otpInitial = {
  verificationCode: '',
};

const otpShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const emailCodeInitial = {
  verificationCode: '',
};

const emailCodeShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const telephoneInitial = {
  verificationCode: '',
};

const telephoneShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(15, errors.incorrectLength)
    .matches(/^\d-\d{3}-\d{4}-\d{4}$/g, errors.incorrectTelephoneFormat)
    .required(errors.fieldRequired),
});

const multiAccountShape = yup.object().shape({
  selectedAccountIndex: yup.number().required(errors.fieldRequired),
});

export {
  loginInitialValues,
  loginShape,
  otpInitial,
  otpShape,
  emailCodeInitial,
  emailCodeShape,
  telephoneInitial,
  telephoneShape,
  multiAccountShape,
};
