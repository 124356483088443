const tfOuput = require('./tf_output.json');

// TODO apiUrl: route that to domain via dns, so getting it manually form sls info won't be needed anymore
const apiUrl = 'https://oe35du7562.execute-api.ap-northeast-1.amazonaws.com';
const browserProxyAddress = `http://${tfOuput.web_proxy_private_ip.value}:8118`;
const dbCluster = tfOuput.documentdb_endpoint.value;
const bucketName = 'aph-dev-puppeteer-screenshots';
const merchantsBucketName = 'aph-dev-backgrounds';
const fxmarketURL = 'https://fxmarketapi.com';
const fxMarketApiKey = '6zEqW9BHCr0gp9ugHO16';
const ppjProcessingURL = 'merchant4.acm-processing.com';
const ppjProcessingKey = '0810055182e23220bc5fa1d1e7e913b2f577bd4c';
const dbUsername = 'aphadmin';
const dbName = 'sample-database';
// const dbHost = "db.dev.t-paytech.com"; // subdomain for widget domain
const dbHost = dbCluster; // TODO domain not working / working too slow - timeout
const region = 'ap-northeast-1';
const aphEnv = 'dev';
const testMerchant = '61b1d43414398df4243a5fc4';
const transactionLimitPerDay = 8;
const minTransferAmount = 10;
const maxTransferAmount = 2.15 * 1000 * 1000;
const unsettledPendingsLimit = 2;
const ftdUnsettledPendingsLimit = 1;
const minSuccessAmount = 40_000;
const nftdFlowStartDate = '2024-03-11';
const ftdTransferLimitAmount = 10_000;
const adminTimezone = { timeZone: 'europe/warsaw' };
const banksLogosUrl =
  'https://aph-dev-banks-logos.s3.ap-northeast-1.amazonaws.com';
const adminApiUrl =
  'https://vc6en3c2rb.execute-api.ap-northeast-1.amazonaws.com/';
const semiRecipientAccountsUrl = 'https://api.merchant.d-paytech.com';
const chromiumLayer =
  'arn:aws:lambda:ap-northeast-1:493746061484:layer:chromium-layer:3';

const lambdaRoleArn = tfOuput.aph_lambda_role.value;
const snsApiAlertsArn = tfOuput.sns_api_alert_arn.value;
const elbName = tfOuput.web_proxy_elb_name.value;
const defaultDailyLimits = {
  total: 20,
  failed: 10,
};

const widget = {
  domain: 'dev.t-paytech.com',
  url: 'https://dev.t-paytech.com',
  hostedZoneId: 'Z03908892M1CBP4BCHAXH',
  certArn:
    'arn:aws:acm:us-east-1:493746061484:certificate/9d22ae7c-81e4-4f68-8805-6993a01e31b7',
};

const widgetPlayground = {
  domain: 'playground.dev.t-paytech.com',
  url: 'https://playground.dev.t-paytech.com',
  hostedZoneId: 'Z03916791O2Z971XT223X',
  certArn:
    'arn:aws:acm:us-east-1:493746061484:certificate/4bd4ca75-ef6f-4d59-ba53-397ee93ae5e5',
};

const admin = {
  domain: 'dev.bo-paytech.com',
  url: 'https://dev.bo-paytech.com',
  hostedZoneId: 'Z101104541FVIBNHSQR2',
  certArn:
    'arn:aws:acm:us-east-1:493746061484:certificate/cfe3de90-892d-4473-a98b-8c1891dfccfc',
};

const customAdmin = {
  domain: 'dev-admin.bo-paytech.com',
  url: 'https://dev-admin.bo-paytech.com',
  hostedZoneId: 'Z00618331ZMQDYRK9OO5D',
  certArn:
    'arn:aws:acm:us-east-1:493746061484:certificate/29ed896a-e381-4bfc-aa70-35e63c308e5b',
};

const proxyEc2 = [
  {
    id: tfOuput.web_proxy_id.value,
    address: `http://${tfOuput.web_proxy_private_ip.value}:8118`,
    name: 'aph_dev_web_proxy_ec2',
    dedicatedFor: [
      'paypay',
      'aujibun',
      'gmoazora',
      'sbisumishin',
      'mizuho',
      'rakuten',
    ],
    vpnConfig: 'tokyo-config-1.ovpn',
    vpnAccessId: '85049751',
    vpnType: 'ExpressVPN',
  },
];

const csvService = {
  name: 'aph-serverless-dev-csvService',
  s3: 'aph-dev-csv-bucket',
};

const accountsStatus = {
  domain: 'status-dev.bo-paytech.com',
  url: 'https://status-dev.bo-paytech.com',
  hostedZoneId: 'Z0966119KVD5XZGX757O',
  certArn:
    'arn:aws:acm:us-east-1:493746061484:certificate/56534689-a9a5-4fcf-b414-2ca1b6fc754e',
};

module.exports = {
  apiUrl,
  browserProxyAddress,
  dbCluster,
  bucketName,
  merchantsBucketName,
  fxmarketURL,
  fxMarketApiKey,
  ppjProcessingURL,
  ppjProcessingKey,
  dbUsername,
  dbHost,
  dbName,
  widget,
  widgetPlayground,
  admin,
  region,
  lambdaRoleArn,
  aphEnv,
  testMerchant,
  transactionLimitPerDay,
  minTransferAmount,
  maxTransferAmount,
  adminTimezone,
  banksLogosUrl,
  snsApiAlertsArn,
  customAdmin,
  adminApiUrl,
  elbName,
  proxyEc2,
  defaultDailyLimits,
  csvService,
  accountsStatus,
  semiRecipientAccountsUrl,
  chromiumLayer,
  unsettledPendingsLimit,
  ftdUnsettledPendingsLimit,
  minSuccessAmount,
  nftdFlowStartDate,
  ftdTransferLimitAmount,
};
