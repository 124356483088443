<!-- svelte-ignore a11y-click-events-have-key-events -->
<script>
  export let account;
  export let isActive;
</script>

<div on:click class={`${isActive ? 'input-container__active' : 'input-container__inactive'}`}>
  <p class="info">{account.accountTitle}</p>
  {account.accountNumber}
  <p class="info">{account.accountBalance} 円</p>
</div>

<style>
  div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    font-weight: bold;
    border-radius: 10px;
  }
  .input-container__inactive {
    background-image: linear-gradient(257deg, #017a4f, #004831);
    color: #fff;
  }

  .input-container__active,
  div:hover {
    cursor: pointer;
    background-image: none;
    background-color: #fff;
    color: #004831;
    border: 1px solid #004831;
  }

  .input-radio {
    visibility: hidden;
  }

  .info {
    width: 100%;
    font-weight: bold;
  }
</style>
