<script>
  import { general as generalStrings } from '../resources/strings';
  import convertToDisplayPrice from '../utils/displayUtils';

  export let price;
  export let transactionFee;
  export let originalAmount;

  $: displayPrice = convertToDisplayPrice(price);
  $: displaytransactionFee = convertToDisplayPrice(transactionFee);
  $: displayOriginalAmount = convertToDisplayPrice(originalAmount);
</script>

<div class="container">
  <span>{generalStrings.amountJPY}</span>
  <div class="price-container">
    <p class="text">
      <span>{generalStrings.depositAmount}</span>
      {displayOriginalAmount} <span>{generalStrings.yen}</span>
    </p>
    <p class="text">
      <span>{generalStrings.transactionFee}</span>
      {displaytransactionFee} <span>{generalStrings.yen}</span>
    </p>
  </div>
  <div class="summary-price-container">
    <h3 class="price">
      <span>{generalStrings.totalAmount}</span>
      {displayPrice} <span>{generalStrings.yen}</span>
    </h3>
  </div>
</div>

<style>
  .container {
    display: grid;
    grid-template-rows: 1fr auto;
  }

  .price-container {
    margin: 10px 0;
  }

  .summary-price-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  span,
  h3 {
    text-align: left;
    text-align: -webkit-left;
  }

  .text {
    font-size: 0.9rem;
    font-weight: 300;
    margin-bottom: 5px;
  }

  .text:nth-last-child(1) {
    margin: 0;
  }

  .price {
    font-size: 1.2rem;
    font-weight: 500;
  }
</style>
