<script>
  import { onMount } from 'svelte';
  import CodeVerification from '../../containers/CodeVerification.svelte';
  import timer from '../../stores/timer.store';
  import AppVerification from './AppVerification.svelte';
  import CustomQuestionVerification from './CustomQuestionVerification.svelte';
  import { smsCodeInitial, smsCodeShape } from './validation';

  export let codeType;
</script>

{#if codeType === 'custom'}
  <CustomQuestionVerification on:verificationSubmit />
{:else if codeType === 'appAuth'}
  <AppVerification />
{:else}
  <CodeVerification
    {codeType}
    validations={{
      smsCode: {
        initial: smsCodeInitial,
        shape: smsCodeShape,
      },
    }}
    on:verificationSubmit
  />
{/if}
