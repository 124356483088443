import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  accountNumberOne: '',
  accountNumberTwo: '',
  accountNumberThree: '',
  password: '',
};

const loginBiometricsInitialValues = {
  accountNumberOne: '',
  accountNumberTwo: '',
  accountNumberThree: '',
};

const loginShape = yup.object().shape({
  accountNumberOne: yup
    .string()
    .min(4, errors.incorrectLength)
    .max(4, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  accountNumberTwo: yup
    .string()
    .min(4, errors.incorrectLength)
    .max(4, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  accountNumberThree: yup
    .string()
    .min(5, errors.incorrectLength)
    .max(5, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  password: yup.string().required(errors.fieldRequired),
});

const loginBiometricsShape = yup.object().shape({
  accountNumberOne: yup
    .string()
    .min(4, errors.incorrectLength)
    .max(4, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  accountNumberTwo: yup
    .string()
    .min(4, errors.incorrectLength)
    .max(4, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  accountNumberThree: yup
    .string()
    .min(5, errors.incorrectLength)
    .max(5, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const otpInitial = {
  verificationCode: '',
};

const otpShape = yup.object().shape({
  verificationCode: yup
    .string()
    .min(7, errors.incorrectLength)
    .max(7, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const telephoneInitial = {
  verificationCode: '',
};

const telephoneShape = yup.object().shape({
  verificationCode: yup
    .string()
    .min(9, errors.incorrectLength)
    .max(13, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const emailCodeInitial = {
  verificationCode: '',
};

const emailCodeShape = yup.object().shape({
  verificationCode: yup
    .string()
    .min(7, errors.incorrectLength)
    .max(7, errors.incorrectLength)
    .required(errors.fieldRequired),
});

export {
  loginInitialValues,
  loginBiometricsInitialValues,
  loginShape,
  loginBiometricsShape,
  otpInitial,
  otpShape,
  telephoneInitial,
  telephoneShape,
  emailCodeInitial,
  emailCodeShape,
};
