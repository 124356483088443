import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  accountNumber: '',
  password: '',
};

const loginShape = yup.object().shape({
  accountNumber: yup
    .string()
    .min(6, errors.incorrectLength)
    .max(32, errors.incorrectLength)
    .required(errors.fieldRequired),

  password: yup
    .string()
    .min(6, errors.incorrectLength)
    .max(32, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const customInitialValues = {
  password: '',
  code1: '',
  code2: '',
};

const customShape = yup.object().shape({
  password: yup
    .string()
    .min(6, errors.incorrectLength)
    .max(32, errors.incorrectLength)
    .required(errors.fieldRequired),
  code1: yup
    .string()
    .length(2, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  code2: yup
    .string()
    .length(2, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const smsCodeInitial = {
  verificationCode: '',
};

const smsCodeShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

export {
  loginInitialValues,
  loginShape,
  customInitialValues,
  customShape,
  smsCodeInitial,
  smsCodeShape,
};
