<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';

  import api from '../../api';

  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';

  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  import { emailCodeInitial, emailCodeShape } from './validation';
  import { general as generalStrings, verificationText } from '../../resources/strings';

  export let codeType;
  export let initialTimer = 5 * 60 - 5; // 4:55

  $: strings = verificationText[codeType];

  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(initialTimer));

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: emailCodeInitial,
    validationSchema: emailCodeShape,
    onSubmit: (submittedForm) =>
      api.postCode($transaction.id, codeType, submittedForm.emailCode).then(() => {
        dispatch('emailVerificationSubmit');
        timer.resetTimer();
      }),
  });
</script>

<form on:submit={handleSubmit} class="container">
  <Input
    label={strings.title}
    name="emailCode"
    placeholder={strings.placeholder}
    bind:value={$form.emailCode}
    error={$errors.emailCode}
    touched={$touched.emailCode}
    {handleChange}
  />
  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}

  <div class="button-container">
    <Button type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 0 2rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .error {
    color: red;
  }
</style>
