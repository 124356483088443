<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import { fly } from 'svelte/transition';
  import Input from '../../components/Input.svelte';
  import LoginButtonsGroup from '../../containers/LoginButtonsGroup.svelte';
  import RadioSign from '../../components/RadioSign.svelte';
  import { transaction } from '../../stores/transaction.store';
  import { selectedBank } from '../../stores/selected-bank.store';
  import { localError, setError, errors as errorsConst } from '../../stores/error.store';
  import { startProgress, progress } from '../../stores/transaction-progress.store';
  import api from '../../api';
  import { paypay as paypayStrings } from '../../resources/strings';
  import { loginShape, loginInitialValues } from './validation';

  const dispatch = createEventDispatcher();

  let submitted = false;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: loginInitialValues,
    validationSchema: loginShape,
    onSubmit: (submittedForm) => {
      if (!submitted) {
        submitted = true;
        return api
          .startCrawler(
            $transaction.id,
            {
              branchNumber: submittedForm.branchNumber,
              accountNumber: submittedForm.accountNumber,
              password: submittedForm.password,
              loginId: submittedForm.loginId,
            },
            $selectedBank.name,
          )
          .then(async (res) => {
            if (res?.ok === false) {
              $progress = 0;
              const data = await res.json();
              if (data.current) {
                setError(data.current, data);
              } else {
                setError(errorsConst.Unknown);
              }
            } else {
              startProgress();
              dispatch('loginSubmit');
            }
          })
          .catch((e) => {
            console.log('ERROR!');
          });
      }
    },
  });
</script>

<form on:submit={handleSubmit} class="container">
  <div class="input-type-selection">
    <RadioSign
      label={paypayStrings.accountSelection}
      value={false}
      bind:group={$form.isLoginIdInput}
      checked={!$form.isLoginIdInput}
      on:change={handleChange}
    />
    <RadioSign
      label={paypayStrings.loginIdSelection}
      value={true}
      bind:group={$form.isLoginIdInput}
      checked={$form.isLoginIdInput}
      on:change={handleChange}
    />
  </div>

  <div>
    <div class="account-container" in:fly={{ x: -200, duration: 300 }}>
      <Input
        name="branchNumber"
        label={paypayStrings.branch}
        placeholder={paypayStrings.branchPlaceholder}
        bind:value={$form.branchNumber}
        error={$errors.branchNumber}
        touched={$touched.branchNumber}
        {handleChange}
      />
      <Input
        name="accountNumber"
        label={paypayStrings.account}
        placeholder={paypayStrings.accountPlaceholder}
        bind:value={$form.accountNumber}
        error={$errors.accountNumber}
        touched={$touched.accountNumber}
        {handleChange}
      />
    </div>
  </div>

  {#if $form.isLoginIdInput}
    <div in:fly={{ x: -200, duration: 300 }}>
      <Input
        name="loginId"
        label={paypayStrings.loginId}
        placeholder={paypayStrings.loginIdPlaceholder}
        bind:value={$form.loginId}
        error={$errors.loginId}
        touched={$touched.loginId}
        {handleChange}
      />
    </div>
  {/if}

  <div class="password-container">
    <Input
      name="password"
      type="password"
      label={paypayStrings.password}
      placeholder={paypayStrings.passwordPlaceholder}
      bind:value={$form.password}
      error={$errors.password}
      touched={$touched.password}
      {handleChange}
    />
  </div>
  {#if $localError.login}
    <div class="login-error">
      <p class="error">{$localError.login}</p>
    </div>
  {/if}
  <LoginButtonsGroup on:back />
</form>

<style>
  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    padding: 0 2rem;
  }

  .account-container,
  .input-type-selection {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
  }

  .password-container {
    width: 100%;
  }

  .login-error {
    width: 100%;
    display: grid;
    place-items: center;
  }

  .error {
    text-align: center;
    color: red;
  }

  @media (max-width: 500px) {
    .input-type-selection {
      gap: 1rem;
      flex-direction: column;
      justify-content: center;
    }
  }
</style>
