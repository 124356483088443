<script>
  export let label;
  export let checked = false;
  export let group;
  export let value;
</script>

<label class="selector" class:active={checked}>
  <input bind:group type="radio" {...$$props} {value} />
  {label}
</label>

<style>
  .selector {
    width: 100%;
    text-align: center;
    padding: 0.5rem 0;
  }

  .selector:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .active {
    border: 1px solid var(--gray-dark);
    border-radius: 4px;
  }

  input[type='radio'] {
    appearance: none;
  }
</style>
