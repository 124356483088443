<script>
  import { fly } from 'svelte/transition';
  import { transaction } from '../../stores/transaction.store';
  import { updateError } from '../../stores/error.store';
  import { state, updateState } from '../../stores/state.store';
  import { isLoading, isLogin, isVerification } from '../../utils/stateUtils';
  import CodeVerification from './CodeVerification.svelte';
  import AppAuth from './AppAuth.svelte';
  import Loading from '../../components/Loading.svelte';
  import Login from './Login.svelte';
  import { resona } from '../../resources/strings';
  import {
    otpInitial,
    otpShape,
    customInitial,
    customShape,
    customPasswordInitial,
    customPasswordShape,
    appAuthInitial,
    appAuthShape,
  } from './validation';

  async function handleStateChange() {
    try {
      await updateState($transaction.id);
    } catch {
      $state.current = 'connectionLost';
    }
    updateError($state.current);
  }
</script>

<div class="card-content">
  {#if isLoading($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <Loading />
    </div>
  {:else if isLogin($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <Login on:back on:loginSubmit={handleStateChange} />
    </div>
  {:else if isVerification($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      {#if $state.customQuestion === 'newAppAuth'}
        <AppAuth on:verificationSubmit={handleStateChange} />
      {:else}
        <CodeVerification
          codeType={$state.current.replace('Retry', '')}
          customQuestion={$state.customQuestion}
          inputType={$state.customQuestion === resona.loginPassword ? 'password' : 'text'}
          validations={{
            otp: {
              inital: otpInitial,
              shape: otpShape,
            },
            appAuth: {
              initial: appAuthInitial,
              shape: appAuthShape,
            },
            custom: {
              inital:
                $state.customQuestion === resona.loginPassword
                  ? customPasswordInitial
                  : customInitial,
              shape:
                $state.customQuestion === resona.loginPassword ? customPasswordShape : customShape,
            },
          }}
          on:verificationSubmit={handleStateChange}
        />
      {/if}
    </div>
  {/if}
</div>

<style>
  .card-content {
    display: grid;
    place-items: center;
    padding: 3rem;
  }

  .transition {
    display: grid;
    place-items: center;
    width: 100%;
  }
  @media (max-width: 550px) {
    .card-content {
      padding: 2rem 0;
    }
  }
</style>
