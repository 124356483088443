<script>
  import { onMount } from 'svelte';
  import { transaction } from '../../stores/transaction.store';
  import { state, updateStateWithPlaceholder } from '../../stores/state.store';
  import { localError, updateError } from '../../stores/error.store';
  import { seven as sevenStrings } from '../../resources/strings';

  import timer from '../../stores/timer.store';
  import api from '../../api';

  const customQuestion = $state.customQuestion;

  $: if ($state.current.includes('Retry')) {
    timer.setNewTimer(5 * 60);
    api.postCode($transaction.id, 'appAuth', 'ready');
    updateStateWithPlaceholder($transaction.id, 'appAuth')
      .then((newState) => updateError(newState.current))
      .catch((e) => updateError(e));
  }

  onMount(() => {
    timer.setNewTimer(5 * 60);
    api.postCode($transaction.id, 'appAuth', 'ready');
    updateStateWithPlaceholder($transaction.id, 'appAuth')
      .then((newState) => updateError(newState.current))
      .catch((e) => updateError(e));
  });
</script>

<div class="container">
  {#if customQuestion}
    <h3 class="title">{sevenStrings.appApprovalTitle}</h3>
    <p class="text">{sevenStrings.appApprovalText}</p>
    <p class="text">{sevenStrings.appApprovalCode(customQuestion)}</p>
    <ol>
      {#each sevenStrings.approvalSteps as step}
        <li>{step}</li>
      {/each}
    </ol>
  {:else}
    <h3 class="title">{sevenStrings.appAuthTitle}</h3>
    <p class="support-text">{sevenStrings.appAuthInfo}</p>
  {/if}
  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}
</div>

<style>
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    place-items: center;
    gap: 1rem;
    padding: 0 2rem;
  }

  .title {
    font-weight: bold;
    text-align: center;
  }

  .text {
    margin: 10px 0;
  }

  .error {
    color: red;
    text-align: center;
  }
</style>
