import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  login: '',
  password: '',
};

const loginShape = yup.object().shape({
  login: yup
    .string()
    .min(6, errors.incorrectLength)
    .max(32, errors.incorrectLength)
    .required(errors.fieldRequired),
  password: yup
    .string()
    .min(6, errors.incorrectLength)
    .max(32, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const customVerificationInitialValues = {
  answers: [],
};

const customVerificationShape = yup.object().shape({
  answers: yup
    .array()
    .of(
      yup
        .string()
        .length(2, errors.incorrectLength)
        .matches(/^\d+$/g, errors.onlyNumbers)
        .required(errors.fieldRequired),
    ),
});

const setInitialValues = {
  appCode: '',
};

const setShape = yup.object().shape({
  appCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

export {
  loginInitialValues,
  loginShape,
  customVerificationInitialValues,
  customVerificationShape,
  setInitialValues,
  setShape,
};
