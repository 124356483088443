<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import api from '../../api';

  import Button from '../../components/Button.svelte';

  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';

  import { fukuoka, general as generalStrings } from '../../resources/strings';

  export let codeType;
  export let customQuestion;
  let initialTimer = 2 * 60; // 2 minutes
  // let { bankPhoneNumber, userNumber } = JSON.parse(customQuestion);
  let { bankPhoneNumber, userNumber } = JSON.parse(
    '{"bankPhoneNumber":"0120-739-161","userNumber":"090-210*-***2"}',
  );

  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(initialTimer));

  function submitPhoneVerification() {
    api.postCode($transaction.id, codeType, 'true').then(() => {
      dispatch('phoneVerificationSubmit');
      timer.resetTimer();
    });
  }
</script>

<div class="container">
  <p>{fukuoka.phoneVerification}<span class="user-number">{userNumber}</span></p>
  <p class="bank-phone-number">{bankPhoneNumber}</p>
  <p>{fukuoka.phoneVerificationConfirmation}</p>
  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}
  <div class="button-container">
    <Button on:click={submitPhoneVerification}>{generalStrings.next}</Button>
  </div>
</div>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 0 2rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .user-number {
    font-weight: bold;
  }
  .bank-phone-number {
    font-size: large;
  }

  .error {
    color: red;
  }
</style>
