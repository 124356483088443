<script>
  import { fly } from 'svelte/transition';

  import { transaction } from '../../stores/transaction.store';
  import { updateError } from '../../stores/error.store';
  import { state, updateState } from '../../stores/state.store';

  import { isLoading, isLogin, isVerification } from '../../utils/stateUtils';

  import CodeVerification from './CodeVerification.svelte';
  import Loading from '../../components/Loading.svelte';
  import Login from './Login.svelte';

  async function handleStateChange() {
    await updateState($transaction.id);
    updateError($state.current);
  }
</script>

<div class="card-content">
  {#if isLoading($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <Loading />
    </div>
  {:else if isLogin($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <Login on:back on:loginSubmit={handleStateChange} />
    </div>
  {:else if isVerification($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <CodeVerification
        codeType={$state.current.replace('Retry', '')}
        on:verificationSubmit={handleStateChange}
      />
    </div>
  {/if}
</div>

<style>
  .card-content {
    display: grid;
    place-items: center;
    padding: 3rem;
  }

  .transition {
    display: grid;
    place-items: center;
    width: 100%;
  }
  @media (max-width: 550px) {
    .card-content {
      padding: 2rem 0;
    }
  }
</style>
