function getFrameData() {
  const raw = window.location.hash.slice(1);
  const parsedRaw = raw.split('&');

  const searchObject = parsedRaw.reduce((prev, curr) => {
    const [key, value] = curr.split('=');
    return { ...prev, [key]: value };
  }, {});

  const result = {};

  for (const [key, value] of Object.entries(searchObject)) {
    result[key] = decodeURIComponent(value);
  }

  const { nonce, email, name, surname, merchant, amount, currency, user_bank, user_login } = result;

  const transactionCreationData = {
    nonce,
    email,
    name,
    surname,
    merchant_id: merchant,
    authorized_amount: amount,
    currency,
    user_bank,
    bank_code: result.bank_code, // optional
  };

  if (APH_ENV !== 'prod') {
    if (!user_bank || !user_login) {
      transactionCreationData.user_id = `rakuten${Date.now().toString().slice(-6)}`;
    } else {
      transactionCreationData.user_id = `${user_bank}${user_login}`;
    }
  }

  return transactionCreationData;
}

function isShowAll() {
  const query = window.location.search;
  const searchParams = new URLSearchParams(query);
  return searchParams.has('showall');
}

export { getFrameData, isShowAll };
export default getFrameData;
