<script>
  import Button from '../components/Button.svelte';
  import { general } from '../resources/strings';
  import { banks } from '../stores/banks.store';
  import { resetAllErrors } from '../stores/error.store';
  import { resetSelectedBank, setSelectedBank } from '../stores/selected-bank.store';
  import { resetState } from '../stores/state.store';
  import { stopProgress } from '../stores/transaction-progress.store';

  $: manualFlag = $banks.some((bank) => bank.name === 'manual');
  $: manualBank = manualFlag && $banks.find((bank) => bank.name === 'manual')

  function handleRetry() {
    resetAllErrors();
    resetSelectedBank();
    resetState();
    stopProgress();
  }

  function selectManualBank() {
    resetAllErrors();
    resetState();
    stopProgress();
    setSelectedBank(manualBank);
  }
</script>

<Button on:click={handleRetry}>{general.retryTrx}</Button>
{#if manualFlag}
  <Button on:click={selectManualBank}>{manualBank.nameJapanese}</Button>
{/if}
