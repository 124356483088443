import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  accountNumber: '',
  password: '',
};

const loginShape = yup.object().shape({
  accountNumber: yup
    .string()
    .min(8, errors.incorrectLength)
    .max(12, errors.incorrectLength)
    .required(errors.fieldRequired),

  password: yup.string().required(errors.fieldRequired),
});

const customInitialValues = {
  type: '',
  branchNumber: '',
  accountNumber: '',
  answers: [],
};

const customShape = yup.object().shape({
  type: yup.string(),

  branchNumber: yup.string().when('type', {
    is: (value) => value === 'advanced',
    then: yup
      .string()
      .length(3, errors.incorrectLength)
      .matches(/^\d+$/g, errors.onlyNumbers)
      .required(errors.fieldRequired),
    otherwise: yup.string().notRequired(),
  }),

  accountNumber: yup.string().when('type', {
    is: (value) => value === 'advanced',
    then: yup
      .string()
      .length(7, errors.incorrectLength)
      .matches(/^\d+$/g, errors.onlyNumbers)
      .required(errors.fieldRequired),
    otherwise: yup.string().notRequired(),
  }),

  answers: yup.array().when('type', {
    is: (value) => value !== 'advanced',
    then: yup.array().of(yup.string().required(errors.fieldRequired)).required(),
    otherwise: yup.array().notRequired(),
  }),
});

const setInitialValues = {
  emailCode: '',
  birthday: '',
  password: '',
};

const setShape = yup.object().shape({
  emailCode: yup.string().length(4, errors.incorrectLength).required(errors.fieldRequired),

  birthday: yup.date().required(errors.fieldRequired),

  password: yup
    .string()
    .min(4, errors.incorrectLength)
    .max(12, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const emailBirthdayInitialValues = {
  emailCode: '',
  birthday: '',
};

const emailBirthdayShape = yup.object().shape({
  emailCode: yup.string().length(4, errors.incorrectLength).required(errors.fieldRequired),
  birthday: yup.date().required(errors.fieldRequired),
});

export {
  loginInitialValues,
  loginShape,
  customInitialValues,
  customShape,
  setInitialValues,
  setShape,
  emailBirthdayInitialValues,
  emailBirthdayShape,
};
