import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  contactNumber: '',
};

const loginShape = yup.object().shape({
  contactNumber: yup.string().required(errors.fieldRequired),
});

export { loginInitialValues, loginShape };
