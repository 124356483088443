<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import { fly } from 'svelte/transition';
  import Input from '../../components/Input.svelte';
  import LoginButtonsGroup from '../../containers/LoginButtonsGroup.svelte';
  import { transaction } from '../../stores/transaction.store';
  import { selectedBank } from '../../stores/selected-bank.store';
  import { localError, setError, errors as errorsConst } from '../../stores/error.store';
  import { startProgress, progress } from '../../stores/transaction-progress.store';
  import api from '../../api';
  import { shinsei as shinseiStrings } from '../../resources/strings';
  import { loginShape, loginInitialValues } from './validations';

  const dispatch = createEventDispatcher();

  let submitted = false;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: loginInitialValues,
    validationSchema: loginShape,
    onSubmit: (submittedForm) => {
      if (!submitted) {
        submitted = true;
        return api
          .startCrawler(
            $transaction.id,
            {
              contactNumber: submittedForm.contactNumber,
              password: submittedForm.password,
            },
            $selectedBank.name,
          )
          .then(async (res) => {
            if (res?.ok === false) {
              $progress = 0;
              const data = await res.json();
              if (data.current) {
                setError(data.current, data);
              } else {
                setError(errorsConst.Unknown);
              }
            } else {
              startProgress();
              dispatch('loginSubmit');
            }
          });
      }
    },
  });
</script>

<form on:submit={handleSubmit} class="container">
  <div>
    <div class="contact-container" in:fly={{ x: 200, duration: 300 }}>
      <Input
        name="contactNumber"
        label={shinseiStrings.contact}
        placeholder={shinseiStrings.contactPlaceholder}
        bind:value={$form.contactNumber}
        error={$errors.contactNumber}
        touched={$touched.contactNumber}
        {handleChange}
      />
    </div>
  </div>

  <div class="password-container">
    <Input
      name="password"
      type="password"
      label={shinseiStrings.password}
      placeholder={shinseiStrings.passwordPlaceholder}
      bind:value={$form.password}
      error={$errors.password}
      touched={$touched.password}
      {handleChange}
    />
  </div>
  {#if $localError.login}
    <div class="login-error">
      <p class="error">{$localError.login}</p>
    </div>
  {/if}
  <LoginButtonsGroup on:back />
</form>

<style>
  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    padding: 0 2rem;
  }

  .contact-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .password-container {
    width: 100%;
  }

  .login-error {
    width: 100%;
    display: grid;
    place-items: center;
  }

  .error {
    text-align: center;
    color: red;
  }
</style>
